<template>
	<div class="outboundProductsList">
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5>
								<strong>Salida de almacén</strong>
							</h5>
						</div>
					</div>
					<div v-if="outboundType == 'service'">
						<div class="row">
							<div class="col-md-4">
								<a-select style="width: 100%" placeholder="Filtro de asignación" v-model="assignmentFilter">
									<a-select-option value=""> Todos </a-select-option>
									<a-select-option v-for="(element, key) in theMechanicalTechnicians" :key="key" :value="element.tallerID"> {{ element.shortName }} </a-select-option>
								</a-select>
							</div>
						</div>
						<a-tabs default-active-key="0">
							<a-tab-pane tab="Todos" key="0" force-render>
								<div class="row">
									<div class="col-md-2 text-center pt10" v-for="(element, index) in serviceOrders" :key="index">
										<a-tooltip>
											<template slot="title">
												<div class="text-center fs20" style="padding: 5px 10px">
													<div>
														<div>
															{{ element.nombre_marca | replaceIfLogicalFalse(element.marca_otro) | replaceIfLogicalFalse('-No establecido-') }}
														</div>
														<div>{{ element.nombre_modelo | replaceIfLogicalFalse(element.modelo_otro) | replaceIfLogicalFalse('-No establecido-') }} {{ element.anio }}</div>
													</div>
												</div>
											</template>
											<a-button class="btn" :class="serviceOrderSelected == element.id ? 'btn-success' : ''" @click="onSelectServiceOrder(element.id)">{{ element.id }} </a-button>
											<div class="text-muted text-asignado">{{ element.nombre_corto | replaceIfLogicalFalse('-No asignado-') }}</div>
										</a-tooltip>
									</div>
								</div>
							</a-tab-pane>
							<a-tab-pane :tab="mt.shortName" v-for="(mt) in theMechanicalTechnicians" :key="mt.tallerID" force-render>
								<div class="row">
									<div class="col-md-2 text-center pt10" v-for="(element, index) in serviceOrders.filter((e) => e.id_administrador == mt.tallerID)" :key="index">
										<a-tooltip>
											<template slot="title">
												<div class="text-center fs20" style="padding: 5px 10px">
													<div>
														<div>
															{{ element.nombre_marca | replaceIfLogicalFalse(element.marca_otro) | replaceIfLogicalFalse('-No establecido-') }}
														</div>
														<div>{{ element.nombre_modelo | replaceIfLogicalFalse(element.modelo_otro) | replaceIfLogicalFalse('-No establecido-') }} {{ element.anio }}</div>
													</div>
												</div>
											</template>
											<a-button class="btn" :class="serviceOrderSelected == element.id ? 'btn-success' : ''" @click="onSelectServiceOrder(element.id)">{{ element.id }} </a-button>
											<div class="text-muted text-asignado">{{ element.nombre_corto | replaceIfLogicalFalse('-No asignado-') }}</div>
										</a-tooltip>
									</div>
								</div>
							</a-tab-pane>
						</a-tabs>
						<div class="row" v-if="hasPending">
							<div class="col-md-12">
								<hr class="hrText" data-content="Insumos por entregar" />
							</div>
							<div class="col-md-2 text-center pt10" v-for="(element, index) in serviceOrdersList.pending" :key="index">
								<a-tooltip>
									<!-- <template slot="title">
									<div class="text-center fs20" style="padding: 5px 10px">
										<div>
											<div>
												{{ element.serviceOrderInfo.nombre_marca | replaceIfLogicalFalse(element.serviceOrderInfo.marca_otro) | replaceIfLogicalFalse('-No establecido-') }}
											</div>
											<div>
												{{ element.serviceOrderInfo.nombre_modelo | replaceIfLogicalFalse(element.serviceOrderInfo.modelo_otro) | replaceIfLogicalFalse('-No establecido-') }}
												{{ element.serviceOrderInfo.anio }}
											</div>
										</div>
									</div>
								</template> -->
									<a-button class="btn" :class="serviceOrderSelected == element.id ? 'btn-success' : 'btn-info2'" @click="onConfirmPending(element.id)">{{ element.id }}</a-button>
									<!-- <div class="text-muted text-asignado">{{ element.serviceOrderInfo.nombre_corto | replaceIfLogicalFalse('-No asignado-') }}</div> -->
								</a-tooltip>
								<!-- <div class="text-muted text-asignado">{{ element.serviceOrderInfo.nombre_corto | replaceIfLogicalFalse('-No asignado-') }}</div> -->
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12">
							<hr class="hrText" data-content="♦" />
						</div>
						<div class="col-md-12">
							<servicesListComponent ref="servicesListComponent" :showTable="false" @rowSelected="onRowSelected" />
						</div>
						<div class="col-md-12">
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<div class="row">
						<div class="pt20" :class="fromPending && serviceOrderSelected != '' ? 'col-md-12' : 'col-md-8'">
							<div class="row" v-if="(!isClosed && outboundType != 'service') || (outboundType == 'service' && serviceOrderSelected)">
								<div class="col-md-9 text-left">
									<b>Buscador de productos por SKU / código de barras </b>
									<a-input-search v-model="productSKU" ref="productSKU" placeholder="Ingresa texto o SKU para buscar" enter-button @search="onSearch" />
								</div>
								<div class="col-md-3 text-right pt20" v-if="orderHasChange && !actualOutboundOrder.id">
									<a-button class="btn btn-success" block icon="save" @click="confirmOutboundOrder">{{ label }}</a-button>
								</div>
							</div>
							<div class="row" v-if="hasCart">
								<div class="col-md-6 text-left pt20">
									<h4 class="text-muted">{{ `${totalProducts} producto${totalProducts == 1 ? '' : 's'}` }}</h4>
								</div>
								<div class="col-md-6 text-right pt10">
									<h1 class="text-muted">Total: {{ numeral(total).format('$0,0.00') }}</h1>
								</div>
							</div>
							<!-- <hr class="hrText" data-content="♦" /> -->
							<div class="row" v-if="(!isClosed && outboundType != 'service') || (outboundType == 'service' && serviceOrderSelected)">
								<div class="col-md-12">
									<a-table :columns="columns" :dataSource="cart" rowKey="id" :pagination="false" v-if="hasCart">
										<div slot="product" slot-scope="record">
											{{ record.label }}
											<br />
											<small>
												<b>SKU</b>: {{ record.product.sku | replaceIfLogicalFalse('-') }}
												<br />
											</small>
											<small v-if="record.receiver"> <b>Recibió:</b> {{ record.receiver.fullName || '' }} </small>
										</div>
										<div slot="amount" slot-scope="record">
											{{ numeral(numeral(record.sale_price).value() * numeral(record.quantity).value()).format('$0,0.00') }}
										</div>
										<div slot="action" slot-scope="record">
											<a-button class="ml5 btn btn-danger" size="small" icon="delete" @click="onDeleteProduct(record.id)" />
										</div>
									</a-table>
								</div>
							</div>
						</div>
						<div class="col-md-4 pt20" v-if="!fromPending && serviceOrderSelected != ''">
							<div class="row">
								<div class="col-md-12 text-left">
									<b>Historial insumos ODS</b>
								</div>
							</div>
							<a-table :columns="columnsDelivered" :dataSource="deliveredProducts()" :pagination="false" v-if="deliveredProducts().length" :rowClassName="(_record, index) =>
								!_record.label.includes(' x ') ? 'rowDanger' : ''
								">
								<div slot="product" slot-scope="record">
									{{ !record.label.includes(' x ') ? `${record.quantity} x ` : '' }} {{ record.label }}
									<br />
									<small>
										<b>SKU</b>: {{ record.product.sku | replaceIfLogicalFalse('-') }}
										<br />
									</small>
								</div>
							</a-table>
							<div class="text-muted text-center" v-else>- No se han entregado insumos -</div>
						</div>
					</div>
					<div class="row pt20">
						<div class="col-md-6 text-left">
							<a-button class="btn btn-warning" icon="arrow-left" @click="onReturn">Regresar</a-button>
						</div>
						<div class="col-md-6 text-right" v-if="cart.length">
							<a-button class="btn btn-danger" icon="close" @click="onCancel">Cancelar</a-button>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
		<!-- Listado de Productos -->
		<a-modal :visible="modal.visible" title="Listado de Productos" :closable="false" width="80%">
			<template slot="footer">
				<a-button key="back" class="btn btn-info" @click="onCloseModal"> Cerrar </a-button>
			</template>
			<productsListComponent @selectedProduct="onSelectProduct" />
		</a-modal>
		<!-- <outboundOrderTicket /> -->
	</div>
</template>

<script>
//
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import utilities from '@/services/utilities'
import { mechanicalTechnicians } from '@/constants'
//
import productsListComponent from '@/components/productsList'
// import outboundOrderTicket from '@/components/outboundOrderTicket'
import servicesListComponent from '@/components/servicesList'

export default {
	name: 'outboundProductsList',
	directives: {
		mask,
	},
	components: {
		productsListComponent,
		// outboundOrderTicket,
		servicesListComponent,
	},
	data() {
		return {
			columnsDelivered: [
				{
					title: 'Producto',
					scopedSlots: { customRender: 'product' },
					width: '55%',
				},
			],
			columns: [
				{
					title: 'Producto',
					scopedSlots: { customRender: 'product' },
					width: '55%',
				},
				{
					title: 'Precio',
					dataIndex: 'sale_price_label',
					width: '10%',
					align: 'right',
				},
				{
					title: 'Importe',
					scopedSlots: { customRender: 'amount' },
					align: 'right',
					width: '10%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '15%',
				},
			],
			modal: {
				visible: false,
			},
			searchText: '',
			searchInput: null,
			productSKU: '',
			cart: [],
			label: 'Confirmar',
			addedProducts: false,
			serviceOrderSelected: '',
			fromPending: false,
			outboundsOrdersID: '',
			assignmentFilter: '',
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('users', ['usersList']),
		...mapGetters('products', ['productsList', 'actualOutboundOrder']),
		...mapGetters('warehouse', ['outboundType']),
		...mapGetters('serviceOrders', ['serviceOrdersList']),
		spinnerLoaderLabel() {
			return this.$store.state.products.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.products.spinnerLoader
		},
		hasCart() {
			return this.cart.length == 0 ? false : true
		},
		hasServiceOrder() {
			return !!utilities.objectValidate(this.$route, 'params.id', false)
		},
		total() {
			let summ = 0
			this.cart.forEach((e) => {
				summ += numeral(e.sale_price).value() * numeral(e.quantity).value()
			})
			return summ
		},
		totalProducts() {
			let summ = 0
			this.cart.forEach((e) => {
				summ += numeral(e.quantity).value()
			})
			return numeral(summ).value()
		},
		isClosed() {
			return !!utilities.objectValidate(this.actualOutboundOrder, 'closed', false)
		},
		orderHasChange() {
			if (utilities.objectValidate(this.actualOutboundOrder, 'order', false)) {
				let theOrder = _.cloneDeep(this.actualOutboundOrder.order)
				return !_.isEqual(this.cart, theOrder)
			} else {
				return this.hasCart
			}
		},
		hasPending() {
			return utilities.objectValidate(this.serviceOrdersList, 'pending', []).length
		},
		serviceOrders() {
			if (this.assignmentFilter != '') {
				return this.serviceOrdersList.open.filter((e) => e.id_administrador == numeral(this.assignmentFilter).value())
			} else {
				return this.serviceOrdersList.open
			}
		},
		theMechanicalTechnicians() {
			// console.log(mechanicalTechnicians.length)
			let response = []
			let localMT = _.cloneDeep(mechanicalTechnicians)

			if (mechanicalTechnicians.length) {
				response = localMT.sort((a, b) => {
					return a.order - b.order
				})
			}

			return response
		},
	},
	destroyed() {
		this.onCleanData()
	},
	mounted() {
		if (this.outboundType == '') {
			this.$router.replace('/almacen')
		}
		if (!this.usersList.length) {
			this.$store.dispatch('users/GET')
		}
		if (this.outboundType != 'service') {
			this.$refs.productSKU.focus()
		}
	},
	methods: {
		moment,
		numeral,
		onCleanData() {
			this.$store.commit('products/SET_STATE', {
				actualOutboundOrder: {},
				productsList: [],
			})
			this.$store.commit('warehouse/SET_STATE', {
				actualOutboundOrder: {},
				outboundType: '',
			})
			this.searchText = ''
			this.searchInput = null
			this.searchedColumn = ''
			this.productSKU = ''
			this.cart = []
			this.addedProducts = false
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onReloadUsers() {
			this.$store.dispatch('users/GET', true)
		},
		onSearch() {
			if (this.productSKU == '' || this.productSKU.length < 3) {
				Swal.fire({
					title: 'Caja',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				this.$store.dispatch('products/SEARCH', { searchText: this.productSKU })
			}
		},
		onDeleteProduct(id) {
			let index = this.cart.findIndex((e) => e.id == id)
			let allCart = _.cloneDeep(this.cart)

			Swal.fire({
				title: 'Atención',
				html: `¿Deseas eliminar <b>${allCart[index].label}</b>?`,
				icon: 'warning',
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.cart = []
					allCart.splice(index, 1)
					this.cart = _.cloneDeep(allCart)
				}
			})
		},
		confirmOutboundOrder() {
			Swal.fire({
				title: 'Atención',
				html: `¿Confirmas la salida de almacén?`,
				icon: 'warning',
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					let payload = {
						order: _.cloneDeep(this.cart),
						useType: this.outboundType,
						service_order: this.outboundType == 'service' ? this.serviceOrderSelected : '',
						require_authorization: false,
						fromPending: this.fromPending,
						receiver: '',
					}

					if (this.fromPending) {
						payload.outboundsOrdersID = this.outboundsOrdersID
					} else {
						if (this.outboundType == 'service') {
							let id_administrador = this.serviceOrdersList.open.find((e) => e.id == this.serviceOrderSelected).id_administrador || 11 // pozos
							payload.receiver = mechanicalTechnicians.find(e => e.tallerID == id_administrador).tallerID
						}
						if (this.outboundType == 'sale') {
							payload.receiver = 20 // Raziel
						}
					}

					if (this.outboundType == 'internal') {
						let users = {}
						this.usersList
							.filter((e) => e.spare_parts_receiver == true)
							.map((element) => {
								users[element.user_id] = element.fullName
							})

						//
						payload.require_authorization = true
						payload.receiver = await Swal.fire({
							icon: 'warning',
							title: `¿Quién recibe los productos?`,
							input: 'select',
							showCancelButton: true,
							reverseButtons: true,
							confirmButtonColor: '#41b883',
							confirmButtonText: 'Continuar',
							cancelButtonColor: '#f5222e',
							cancelButtonText: 'Cancelar',
							inputOptions: {
								...users,
							},
							inputPlaceholder: 'Selecciona al usuario',
							inputValidator: (value) => {
								return new Promise((resolve) => {
									if (value) {
										resolve()
									} else {
										resolve('Es necesario seleccionar a un usuario')
									}
								})
							},
						}).then(async (result) => {
							return this.usersList.find((e) => e.user_id == Number(result.value)).user_id
						})
					}

					if (this.hasServiceOrder) {
						payload.service_order = numeral(this.$route.params.id).value()
						await this.$store.dispatch('products/UPDATE_OUTBOUND_ORDER', payload)
					} else {
						await this.$store.dispatch('products/CREATE_OUTBOUND_ORDER', payload)
					}
					this.$store.dispatch('notifications/GET')
				}
			})
		},
		getPrice(string) {
			if (!string) {
				return 0
			}
			return numeral(JSON.parse(string).sale_price).value()
		},
		onCloseModal() {
			this.modal.visible = false
		},
		onSelectProduct(product) {
			let quantity = utilities.objectValidate(product, 'quantity', 1)
			let sale_price = this.getPrice(product.prices)
			let amount = sale_price * quantity
			let index = this.cart.findIndex((e) => e.id == product.id)

			if (index == -1) {
				this.cart.unshift({
					product,
					id: product.id,
					label: `${quantity} x ${product.label}`,
					sale_price_label: numeral(sale_price).format('$0,0.00'),
					sale_price,
					quantity,
					amount_label: numeral(amount).format('$0,0.00'),
					amount,
				})
			} else {
				//
				let allCart = _.cloneDeep(this.cart)
				let cart = _.cloneDeep(allCart[index])
				quantity = cart.quantity + 1
				amount = cart.sale_price * quantity

				this.cart = []
				cart = {
					...cart,
					label: `${quantity} x ${product.label}`,
					sale_price_label: numeral(sale_price).format('$0,0.00'),
					sale_price,
					quantity,
					amount_label: numeral(amount).format('$0,0.00'),
					amount,
				}

				allCart[index] = _.cloneDeep(cart)
				this.cart = allCart
			}
			this.productSKU = ''
			this.onCloseModal()
		},
		onReturn() {
			this.$router.replace('/almacen')
		},
		onCancel() {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas cancelar la salida de productos?`,
				icon: 'warning',
				reverseButtons: true,
				showCancelButton: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.onCleanData()
				}
			})
		},
		onSelectServiceOrder(id) {
			let record = this.serviceOrders.find((e) => e.id == id)
			this.cart = []
			// console.log('record-->', record);
			this.$refs.servicesListComponent.cleanData()

			if (utilities.objectValidate(record, 'id_marca', false)) {
				this.$refs.servicesListComponent.setLocalValues('brandId', record.id_marca)
				this.$refs.servicesListComponent.onChangeBrand(record.id_marca)
			}
			if (utilities.objectValidate(record, 'id_modelo', false)) {
				setTimeout(() => {
					this.$refs.servicesListComponent.setLocalValues('modelId', record.id_modelo)
				}, 2000);
			}

			this.fromPending = false
			this.serviceOrderSelected = id
		},
		deliveredProducts() {
			return utilities.objectValidate(
				this.serviceOrders.find((e) => e.id == this.serviceOrderSelected),
				'deliveredProducts',
				[],
			)
		},
		onConfirmPending(id) {
			let actualOrder = this.serviceOrdersList.pending.find((e) => e.id == id)
			let order = JSON.parse(actualOrder.order)
			this.outboundsOrdersID = actualOrder.outboundsOrdersID
			this.serviceOrderSelected = id
			this.fromPending = true
			this.$store.commit('warehouse/SET_STATE', {
				outboundType: 'service',
			})

			order.forEach((product) => {
				this.onSelectProduct(product)
			})
		},
		onRowSelected(payload) {
			// console.log('onRowSelected-->', payload);
			this.productSKU = payload
			setTimeout(() => {
				this.onSearch()
			}, 10);
		},
	},
	watch: {
		productsList: {
			deep: true,
			handler(newData) {
				if (newData.length == 1) {
					this.onSelectProduct(newData[0])
				}
				if (newData.length > 1) {
					this.modal.visible = true
				}
			},
		},
		serviceOrderSelected() {
			setTimeout(() => {
				this.$refs.productSKU.focus()
			}, 100)
		},
	},
}
</script>

<style lang="scss">
.outboundProductsList {
	.text-asignado {
		margin-top: -2px;
		font-size: 12px;
	}

	.ant-table-row-cell-break-word {
		padding: 5px 10px !important;
	}

	.rowDanger {
		background-color: #f5c2c7;
	}
}
</style>